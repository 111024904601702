
import React from 'react'
import { graphql, Link } from 'gatsby'
import { Treemap, hierarchy, treemapSquarify } from '@visx/hierarchy'
import { Group } from '@visx/group'


export default function MonitorPorts({ 
	data,
}) {

	const treeRoot = React.useMemo(() => {

		const root = {}

		for (const { ports } of data.allMonitor.nodes) {
			for (const port of ports) {

				if (!root[port.name]) {
					root[port.name] = {
						name: port.name,
						children: {}
					}
				}

				const type = (port.attrs.type ?? 'standard').replace(/ /g, '-')
				if (!root[port.name].children[type]) {
					root[port.name].children[type] = {
						name: type,
						children: {}
					}
				}

				const version = port.attrs.version ?? 'standard'
				if (!root[port.name].children[type].children[version]) {
					root[port.name].children[type].children[version] = {
						name: version,
						value: 0
					}
				}

				root[port.name].children[type].children[version].value += (port.count || 1)
			}
		}

		const rollUp = node => {
			const item = { name: node.name }
			if (!!node.value)
				item.value = node.value
			if (!!node.children)
				item.children = Object.values(node.children).map(child => rollUp(child))
			return item
		}

		const rootMap = rollUp({
			name: 'root',
			children: root
		})
		// console.log(root, rootMap)
		// return hierarchy(rootMap).sum(d => d.value).sort((a, b) => b.value - a.value)
		// return hierarchy(rootMap)
		return hierarchy(rootMap).sum(d => d.value)

	}, [data])

	const width = 800
	const height = 400
	// console.log(treeRoot)

	return <main className='Page'>

		<Link to='/monitors/'>← Monitors List</Link>
		<h1>Monitor Ports/Connectors Guide</h1>

		<svg width={width} height={height}>
			<Treemap
				root={treeRoot}
				size={[width, height]}
				tile={treemapSquarify}
				round={true}
				paddingTop={20}
				paddingInner={5}
				paddingOuter={3}
			>
				{ treemap => (
					<Group>
						{ treemap.descendants().map((node, i) => {
							// console.log(node)
							return <g>
								<rect
									fill={node.depth === 1 ? 'teal' : 'khaki'}
									stroke={'coral'}
									x={node.x0}
									y={node.y0}
									width={node.x1 - node.x0}
									height={node.y1 - node.y0}
								/>
								<text
									x={node.x0}
									y={node.y0}
									dominantBaseline={'hanging'}
									textAnchor={'start'}
								>
									{ node.data.name }
								</text>
							</g>
						})}
					</Group>
				)}
			</Treemap>
				{/* {treemap => (
				<Group>
					{treemap
					.descendants()
					.reverse()
					.map((node, i) => {
						const nodeWidth = node.x1 - node.x0;
						const nodeHeight = node.y1 - node.y0;
						const background = 'gold'
						console.log(node)
						return (
							<Group
							key={`node-${i}`}
							top={node.y0}
							left={node.x0}
							>
							{node.depth === 1 && (
								<rect
									width={nodeWidth}
									height={nodeHeight}
									stroke={background}
									strokeWidth={4}
									fill="transparent"
								/>
							)}
							{node.depth > 2 && (
								<rect
									width={nodeWidth}
									height={nodeHeight}
									stroke={background}
								//  fill={colorScale(node.value || 0)}
									fill={'teal'}
								/>
							)}
							</Group>
						);
					})}
					</Group>)}
			</Treemap> */}
			
		</svg>

		<pre>{ JSON.stringify(data, null, 2)}</pre>

	</main>
}


export const pageQuery = graphql`  
	query {
		allMonitor {
			nodes {
				ports
			}
		}
	}
`
